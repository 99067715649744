// import logo from "./logo.svg";
import "./App.css";

import {Route, Routes} from "react-router-dom";
import "./Assets/Styles/style.scss";

function App() {
  const BusinessCard = () => {
    window.location.href = "https://hihello.me/p/826eaa04-584c-4e6f-9092-5ffda6b10676";
    return <></>
  }
  const routes = (
    <>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="*"  element={<BusinessCard />} />
      </Routes>
    </>
  );
  return <div className="App">{routes}</div>;
}

export default App;
